import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet"

const Contact = () => {
    const [alert, setAlert] = useState(0);
    const [res, setRes] = useState("");
    const contactUsStbmit = () => {
        let name = document.getElementsByName('name')[0].value;
        let email = document.getElementsByName('email')[0].value;
        let phone = document.getElementsByName('phone')[0].value;
        let text = document.getElementsByName('message')[0].value;
        if (name !== "" && email !== "" && phone !== "" && text !== "") {
            var formdata = new FormData();
            formdata.append("your-name", name);
            formdata.append("your-email", email);
            formdata.append("phone", phone);
            formdata.append("your-message", text);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://api-menaiotalliance.iotaci.com/wp-json/contact-form-7/v1/contact-forms/241/feedback", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setRes(result.message);
                    setAlert(1);
                    document.getElementById('contactForm').reset();
                })
                .catch(error => console.log('error', error));
        }

    }
    return (
        <>
            <Helmet>
                <title>Mena IoT Alliance - Contact Us</title>
            </Helmet>
            <section className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small"
                     style={{"visibility": "visible", "animation-name": "fadeIn"}}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                Contact Us
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li className="text-primary">Contact</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wow animate__fadeIn">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row justify-content-center">
                                <div
                                    className="col-12 col-xl-6 col-lg-7 text-center margin-4-half-rem-bottom md-margin-3-rem-bottom">
                            <span
                                className="alt-font letter-spacing-minus-1-half text-extra-medium d-block margin-5px-bottom">Fill
                                out the form and we’ll be in touch soon!</span>
                                    <h4 className="alt-font font-weight-600 text-extra-dark-gray">How we can help
                                        you?</h4>
                                </div>
                                <div className="col-12">
                                    {(alert === 0) ? "" :
                                        <div
                                            className="col-12 margin-35px-bottom alert alert-success alert-dismissible fade in show"
                                            role="alert">

                                            <strong>Success!</strong> {res}
                                        </div>
                                    }
                                    <form id="contactForm">
                                        <div className="row row-cols-1 row-cols-md-2">
                                            <div className="col margin-4-rem-bottom sm-margin-25px-bottom">
                                                <input className="medium-input bg-white margin-25px-bottom required"
                                                       type="text"
                                                       name="name" placeholder="Your name"/>
                                                <input className="medium-input bg-white margin-25px-bottom required"
                                                       type="email"
                                                       name="email" placeholder="Your email address"/>
                                                <input className="medium-input bg-white mb-0" type="tel"
                                                       name="phone"
                                                       placeholder="Your mobile"/>
                                            </div>
                                            <div className="col margin-4-rem-bottom sm-margin-10px-bottom">
                                        <textarea className="medium-textarea h-200px bg-white required" name="message"
                                                  placeholder="Your message" required></textarea>
                                            </div>
                                            <div className="col text-start sm-margin-30px-bottom">
                                                <div className={"d-none d-md-inline"}>
                                                    <i
                                                    className="feather icon-feather-mail icon-very-small margin-10px-right"></i><a
                                                    href="#">info@yourdomain.com</a>
                                                </div>
                                            </div>
                                            <div className="col text-center text-md-end">
                                                <input type="hidden" name="redirect" value=""/>
                                                <button onClick={contactUsStbmit}
                                                        className="btn btn-medium btn-primary mb-0 submit"
                                                        type="button">Send Message
                                                </button>
                                            </div>
                                            <div className={"d-md-none mt-5 text-center"}>
                                                <i
                                                    className="feather icon-feather-mail icon-very-small margin-10px-right"></i><a
                                                href="#">info@yourdomain.com</a>
                                            </div>
                                        </div>
                                        <div className="form-results d-none"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;