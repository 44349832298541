import React from 'react';
import {Route, Switch} from "react-router-dom";
import IndexPage from "../pages/Home/IndexPage";
import About from "../pages/Common/About";
import Contact from "../pages/Common/Contact";
import JoinUs from "../pages/Common/JoinUs";
import BlogList from "../pages/Blog/BlogList";
import BlogDetail from "../pages/Blog/BlogDetail";
import BlogListByCategory from "../pages/Blog/BlogListByCategory";
import BlogListByTag from "../pages/Blog/BlogListByTag";
import Sitemap from "../pages/Common/Sitemap";
import NotFound from "../pages/Common/NotFound";

const RoutSwitch = () => {
    return (
        <Switch>
            <Route path="/" exact sitemapIndex={true}>
                <IndexPage/>
            </Route>
            <Route path="/about" exact sitemapIndex={true}>
                <About/>
            </Route>
            <Route path="/contact" exact sitemapIndex={true}>
                <Contact/>
            </Route>
            <Route path="/join-us" exact sitemapIndex={true}>
                <JoinUs/>
            </Route>
            <Route path="/blog" exact sitemapIndex={true}>
                <BlogList/>
            </Route>
            <Route path="/blog/:slug" exact sitemapIndex={true}>
                <BlogDetail/>
            </Route>
            <Route path="/blog/category/:slug" exact sitemapIndex={true}>
                <BlogListByCategory/>
            </Route>
            <Route path="/blog/tag/:slug" exact sitemapIndex={true}>
                <BlogListByTag/>
            </Route>
            <Route path="/sitemap.xml" exact>
                <Sitemap/>
            </Route>
            <Route path="*" exact >
                <NotFound/>
            </Route>
        </Switch>
    );
};

export default RoutSwitch;