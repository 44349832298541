import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Comment = () => {
    return (
        <div
            className="d-block d-md-flex w-100 align-items-center align-items-md-start ">
            <div className="w-75px sm-w-50px sm-margin-10px-bottom">
                <Skeleton height={'96px'} width={'96px'} circle={true}/>
            </div>
            <div
                className="w-100 padding-25px-left last-paragraph-no-margin sm-no-padding-left">
                <p
                    className="text-extra-dark-gray text-fast-blue-hover alt-font font-weight-500 text-medium">
                    <Skeleton width="40%"/>
                </p>
                {/*<a href="#comments"*/}
                {/*   className="btn-reply text-medium-gray text-uppercase section-link">Reply</a>*/}
                <div className="text-medium text-medium-gray margin-15px-bottom">
                    <Skeleton width="10%"/>
                </div>
                <p className="w-85">
                    <Skeleton count={4}/>
                </p>
            </div>
        </div>
    );
};

export default Comment;