import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from "react-router-dom";

const BlogCardSkeleton = () => {

    return (
        <div className="grid-item wow animate__fadeIn" data-wow-delay="0.2s">
            <div className="blog-post margin-20px-bottom">
                <div
                    className="blog-post-image margin-15px-bottom md-margin-35px-bottom xs-margin-25px-bottom">
                    <Link to={'#'}>
                        <Skeleton height="240px" />
                    </Link>
                </div>
                <div
                    className="post-details margin-15px-bottom md-margin-10px-bottom xs-no-margin-bottom">
                    <Link to={'#'}
                          className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray d-block margin-10px-bottom xs-margin-10px-bottom blogtextheight">
                        <Skeleton  width="60%"/>
                    </Link>
                    <p className="w-95 mb-2">
                        <Skeleton  width="30%"/>
                    </p>
                    {/*<span*/}
                    {/*    className="separator bg-gradient-light-purple-light-orange"></span>*/}
                    <Link to={'#'}
                          className="alt-font font-weight-500 text-extra-small text-uppercase text-gradient-light-purple-light-orange"><Skeleton  weight="10%"/></Link>
                </div>
            </div>
        </div>
    );
};

export default BlogCardSkeleton;