import React from 'react';
import { Link } from "react-router-dom";
import PartnersLogo from "../../components/Common/PartnersLogo";
import { Helmet } from "react-helmet";
import PartnersLogoSkeleton from "../../components/Common/PartnersLogoSkeleton";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        fetch('https://api-menaiotalliance.iotaci.com/wp-json/wp/v2/partner')
            .then(res => res.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            });
    }

    render() {
        const { items } = this.state;
        return (
            <>
                <Helmet>
                    <title>Mena IoT Alliance - About Us</title>
                </Helmet>

                <section
                    className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small"
                    style={{ visibility: "visible", animationName: "fadeIn" }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                                <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                    Contact Us
                                </h1>
                                {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                            </div>
                            <div
                                className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="text-primary">About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="big-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center margin-eight-bottom">
                                <h4 className="alt-font text-extra-dark-gray letter-spacing-minus-1px font-weight-600 margin-4-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">We
                                    are MENA IoT Alliance</h4>
                                <p className="w-100 xs-w-100">
                                    Mena IoT Alliance built a community of technology leaders focused on bringing IoT
                                    products to market. This community is focused on leveraging the power of IoT and
                                    related technologies to use within existing and future IoT ecosystems.
                                </p>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 client-logo-style-02">
                            {
                                (this.state.isLoaded === true) ?
                                    <PartnersLogo elements={items} /> :
                                    <PartnersLogoSkeleton />
                            }
                        </div>
                    </div>
                </section>
                {/*About Us-----------------------*/}
                <section className="big-section cover-background"
                    style={{
                        backgroundImage: "url('/assets/images/bg-aboutabout.jpg')"
                    }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div
                                className="col-10 col-lg-6 position-relative md-margin-9-rem-bottom wow animate__fadeIn">
                                <div className="w-70 overflow-hidden position-relative xs-w-80">
                                    <img src="/assets/images/our-process-05.jpg" alt="" />
                                </div>
                                <div
                                    className="position-absolute right-35px bottom-minus-50px w-50 lg-bottom-minus-30px xs-right-15px"
                                    data-wow-delay="0.2s" data-parallax-layout-ratio="1.1">
                                    <img src="/assets/images/our-process-06.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-10 col-xl-4 col-lg-5 offset-lg-1">
                                <h4 className="alt-font text-extra-dark-gray letter-spacing-minus-1px font-weight-600 margin-4-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">We
                                    are a global hub of IoT Providers</h4>
                                <div className="row">

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.2s">
                                        <div className="feature-box feature-box-left-icon">
                                            <div className="feature-box-content last-paragraph-no-margin">
                                                <span
                                                    className="alt-font text-black font-weight-500 d-block margin-5px-bottom">Vision</span>
                                                <p className="w-85 xs-w-100">
                                                    We provide a global hub for connecting businesses and companies to
                                                    each other.

                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.2s">
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-3-rem-tb md-margin-2-rem-tb" />
                                    </div>

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.4s">
                                        <div className="feature-box feature-box-left-icon">
                                            <div className="feature-box-content last-paragraph-no-margin">
                                                <span
                                                    className="alt-font text-black font-weight-500 d-block margin-5px-bottom">Mission</span>
                                                <p className="w-85 xs-w-100">
                                                    We empower businesses and companies' human resources to benefit the
                                                    high-tech companies' experiences.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Company -----------------------*/}
                <section className="big-section overlap-height wow animate__fadeIn">
                    <div className="container">
                        <div className="row align-items-center overlap-gap-section">
                            <div
                                className="col-12 col-lg-5 col-md-8 order-md-2 order-lg-1 sm-margin-5-rem-bottom wow animate__fadeIn"
                                data-wow-delay="0.1s">
                                <h4 className="alt-font font-weight-600 text-slate-blue letter-spacing-minus-1px margin-30px-bottom sm-margin-20px-bottom">
                                    About MENA IoT Alliance
                                </h4>
                                <p className="w-80 lg-w-100">IoT Academy powers Mena IoT Alliance as an ITU center of
                                    excellence (CoE) in the priority area of Internet of Things in Asia and the Pacific
                                    region, and Academia member of ITU (International Telecommunication Union), and ???
                                    (Omanian company name), as a ???. This community is focused on connecting the
                                    businesses from MENA (the Middle East and North Africa) region to the
                                    world-validated companies and businesses. This community also provides many
                                    specialized training courses and events in IoT and related technologies, smart
                                    cities, industry 4.0, industry 5.0, and digital transformation. It is an IoT
                                    ecosystem for realizing the increasing citizen awareness, empowering HR &
                                    organizations, IoT and smart city consulting services, IoT and smart city
                                    laboratory, research and development, and collaboration with business
                                    accelerators.</p>
                                {/*<blockquote*/}
                                {/*    className="w-80 border-width-5px border-color-neon-orange text-extra-medium padding-30px-left md-w-100 no-padding-right line-height-26px no-margin-bottom margin-40px-top lg-w-100 xs-padding-20px-left">I*/}
                                {/*    like the quiet life sometimes. I also love a bustling press conference sometimes as*/}
                                {/*    well.*/}
                                {/*    <span*/}
                                {/*        className="alt-font font-weight-500 text-medium text-neon-orange text-uppercase letter-spacing-1px d-block margin-20px-top">fredric martin</span>*/}
                                {/*</blockquote>*/}
                            </div>
                            <div
                                className="col-12 col-lg-4 order-md-1 order-lg-2 md-margin-5-rem-bottom wow animate__fadeIn"
                                data-wow-delay="0.3s">
                                <img src="/assets/images/aboutus.jpg" alt="" className="border-radius-6px" />
                            </div>
                            <div className="col-12 col-lg-2 offset-md-1 col-md-3 order-md-3 wow animate__fadeIn"
                                data-wow-delay="0.5s">
                                <div className="row align-items-center justify-content-center">

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                data-to="4000">4000</h4>
                                            <span
                                                className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-medium text-uppercase d-block">People<br />trained</span>
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"></div>
                                    </div>

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                data-to="30">30</h4><span
                                                    className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-medium text-uppercase d-block">Companies<br />consulted</span>
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"></div>
                                    </div>

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                data-to="300">300</h4><span
                                                    className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-medium text-uppercase d-block">training<br />courses held</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Manager -----------------------*/}
                <section class="position-relative overflow-visible wow animate__fadeIn" data-wow-delay="0.4s">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-4 position-relative margin-70px-top lg-margin-30px-top md-margin-50px-bottom">
                                {/* <div class="w-70 border-radius-6px overflow-hidden position-relative">
                                    <img src="/assets/images/our-process-05.jpg" alt="" />
                                    <div class="opacity-extra-medium bg-gradient-sky-blue-pink"></div>
                                </div> */}
                                <div class="" data-parallax-layout-ratio="1.1">
                                    <img class="border-radius-6px" src="/assets/images/our-process-06.jpg" alt="" />
                                </div>
                            </div>
                            <div class="col-12 col-lg-7 offset-lg-1">
                                {/* <div class="alt-font text-extra-medium font-weight-500 margin-30px-bottom">
                                    <span class="w-30px h-1px bg-fast-blue d-inline-block align-middle margin-20px-right"></span>
                                    <span class="text-fast-blue d-inline-block">Looking for interface expertise?</span>
                                </div> */}
                                <h5 class="alt-font text-extra-dark-gray font-weight-500 margin-30px-bottom w-95">We design brand, digital experience that engaged the right customers</h5>
                                <p class="w-95">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation ullamco.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    };
}

export default About;
