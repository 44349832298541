import {Link} from "react-router-dom";

const About = () => {

    return (
        <section className="margin-3-rem-bottom p-0">
            <div className="container">
                <div className="row align-items-center">
                    <div
                        className="col-12 col-xl-7 col-lg-6 position-relative md-margin-8-rem-bottom xs-margin-10-rem-bottom wow animate__fadeIn"
                        data-wow-delay="0.2s">
                        <div className="w-70">
                            <img src="/assets/images/Site/about-left.jpg" alt=""/>
                        </div>
                        <div className="position-absolute right-15px bottom-minus-50px w-50"
                             data-parallax-layout-ratio="1.1">
                            <img src="/assets/images/Site/about-right.jpg" alt=""/>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 offset-lg-1 col-lg-5">
                            <span
                                className="alt-font font-weight-600 text-neon-orange text-uppercase letter-spacing-2px d-block margin-30px-bottom xs-margin-20px-bottom">15 Years Experience</span>
                        <h4 className="alt-font font-weight-700 text-slate-blue letter-spacing-minus-1px text-uppercase margin-35px-bottom">We
                            are a Global Partnership of
                            Internet of Things Providers</h4>
                        <p>“Mena IoT Alliance built a community of technology leaders focused on bringing IoT products to market. This community is focused on leveraging the power of IoT and related technologies to use within existing and future IoT ecosystems.</p>
                        <Link to="/join-us" className="btn btn-large btn-primary margin-20px-top">JOIN US NOW</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;