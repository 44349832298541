import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard, Pagination} from "swiper";

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [], isLoaded: false,
        };
    }

    componentDidMount() {
        fetch('https://api-menaiotalliance.iotaci.com/wp-json/wp/v2/slider')
            .then(res => res.json())
            .then(result => {
                this.setState({
                    isLoaded: true, items: result
                });
            });
    }

    render() {
        const {items} = this.state;
        return (
            <section className="p-0 main-slider margin-3-rem-bottom">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay, Keyboard]}
                    className={'swiper-container white-move mobileoff-fullscreen-top-space md-h-600px sm-h-500px'}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    keyboard={{enabled: true, onlyInViewport: true}}
                    navigation={{
                        prevEl: ".swiper-button-previous-nav",
                        nextEl: ".swiper-button-next-nav",
                    }}
                    pagination={{
                        el: ".swiper-pagination",
                        clickable: true,
                    }}
                >
                    <div className="swiper-wrapper">
                        {items.map((item, i) =>
                            <SwiperSlide className="cover-background"
                                         style={{"backgroundImage": "url('" + item.image.guid + "')"}} key={i}>
                                <div className="overlay-bg bg-gradient-dark-slate-blue"/>
                                <div className="container h-100">
                                    <div className="row h-100">
                                        <div
                                            className="col-12 col-xl-8 col-lg-7 col-sm-8 h-75 d-flex justify-content-center flex-column position-relative">

                                            <div
                                                className="alt-font text-extra-large text-white opacity-7 font-weight-300"
                                                dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                            <h2 className="alt-font text-shadow-double-large font-weight-600 text-white margin-55px-bottom w-90 md-w-100 md-no-text-shadow xs-margin-35px-bottom">
                                                {item.title.rendered}
                                            </h2>
                                            <div className="d-inline-block">
                                                <a href={item.button_link} target="_blank" rel="noreferrer"
                                                   className="btn btn-fancy btn-medium btn-primary margin-30px-right xs-margin-15px-bottom">{item.button_text}</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}


                    </div>

                    <div className="swiper-pagination swiper-light-pagination"/>

                </Swiper>
            </section>
        );
    }

}

export default Slider;
