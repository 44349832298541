import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Keyboard, Autoplay} from "swiper";
import BlogCard from "../Blog/BlogCard";

class LatestBlog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        fetch('https://api-menaiotalliance.iotaci.com/?rest_route=/wp/v2/posts&_embed&per_page=3&page=1')
            .then(res => res.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            });

    }

    render() {
        const {items} = this.state;
        SwiperCore.use([Autoplay, Navigation])
        return (
            <div
                className="col-12 col-md-9 col-lg-12 position-relative wow animate__fadeIn"
                data-wow-delay="0.2s">
                <Swiper
                    modules={[Autoplay, Navigation, Keyboard]}
                    className={'black-move blog-classic'}
                    spaceBetween={30}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    breakpoints={{
                        992: {
                            slidesPerView: 3,
                            autoplay: {
                                delay: 9999999
                            }
                        }
                    }}
                    keyboard={{enabled: true, onlyInViewport: true}}

                    navigation={{
                        prevEl: ".swiper-button-previous-nav",
                        nextEl: ".swiper-button-next-nav",
                    }}
                >

                    <div className="swiper-wrapper">
                        {
                            items.map((item, i) =>
                                <SwiperSlide key={i}>
                                    <BlogCard elements={item}/>
                                </SwiperSlide>
                            )}
                    </div>

                </Swiper>
                <div
                    className="swiper-button-previous-nav swiper-button-prev light slider-navigation-style-07 rounded-circle box-shadow-double-large">
                    <i className="feather icon-feather-arrow-left"></i></div>
                <div
                    className="swiper-button-next-nav swiper-button-next light slider-navigation-style-07 rounded-circle box-shadow-double-large">
                    <i className="feather icon-feather-arrow-right"></i></div>
            </div>

        )
    }
}

export default LatestBlog;