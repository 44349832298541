import React from 'react';

const SharePage = () => {
    let pageUrl= window.location.href;
    let pageSource = window.location.host
    let pageTitle = document.title;
    return (
        <div className="col-12 text-center elements-social social-icon-style-09 mx-auto">
            <ul className="medium-icon">
                <li className="wow animate__fadeIn" data-wow-delay="0.2s">
                    <a className="facebook"
                       href={"https://www.facebook.com/sharer/sharer.php?m2w&s=100&p[url]=" + pageUrl +"&p[images][0]=@Model.CoverImg&p[title]=" + pageTitle +"&p[summary]=@HttpContext.Current.Request.Url.AbsoluteUri"}
                       target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f"></i><span></span>
                    </a>
                </li>
                <li className="wow animate__fadeIn" data-wow-delay="0.3s">
                    <a className="twitter"
                       href={"https://twitter.com/intent/tweet/?text=" + pageTitle +"&url=" + pageUrl +""}
                       target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter"></i><span></span>
                    </a>
                </li>
                <li className="wow animate__fadeIn" data-wow-delay="0.4s">
                    <a className="behance"
                       href={"https://telegram.me/share/url?url=" + pageUrl +"&text=" + pageTitle +""}
                       target="_blank" rel="noreferrer">
                        <i className="fab fa-telegram-plane"></i><span></span>
                    </a>
                </li>
                <li className="wow animate__fadeIn" data-wow-delay="0.5s">
                    <a className="linkedin"
                   href={"http://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl +"&source="+ pageSource +""}
                       target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i><span></span>
                    </a>
                </li>
                <li className="wow animate__fadeIn" data-wow-delay="0.6s">
                    <a className="whatsapp"
                       href={"whatsapp://send?text=" + pageUrl +""}
                       target="_blank" rel="noreferrer">
                        <i className="fab fa-whatsapp"></i><span></span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default SharePage;