import React from 'react';
import Header from "./header";
import Footer from "./footer";
const Layout = ({children}) => {
    return (
        <>
            <Header/>
            {children}
            <Footer/>
            <a className="scroll-top-arrow" href="#"><i
                className="feather icon-feather-arrow-up"></i></a>

        </>
    );
};

export default Layout;