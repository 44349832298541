import React from 'react';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import Layout from "./layout/Layout";
import ChangeRoute from "./Tools/ChangeRoute";
import RoutSwitch from "./RoutSwitch";

const Roating = () => {
    return (
        <Router>
            <div className="App">
                <Layout>
                    <ChangeRoute/>
                    <RoutSwitch/>
                </Layout>
            </div>
        </Router>

    );
};

export default Roating;